import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from '@raas/shared/src/components/minimals/logo';
import Image from '@raas/shared/src/components/minimals/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
export default function LoginLayout(_a) {
    var children = _a.children, illustration = _a.illustration, title = _a.title;
    return (_jsxs(StyledRoot, { children: [_jsx(Logo, { sx: {
                    zIndex: 9,
                    position: 'absolute',
                    mt: { xs: 1.5, md: 5 },
                    ml: { xs: 2, md: 5 },
                } }), _jsxs(StyledSection, { children: [_jsx(Typography, { variant: "h3", sx: { mb: 10, maxWidth: 480, textAlign: 'center' }, children: title || 'Hi, Welcome back' }), _jsx(Image, { disabledEffect: true, visibleByDefault: true, alt: "auth", src: illustration || '/assets/illustrations/illustration_dashboard.png', sx: { maxWidth: 720 } }), _jsx(StyledSectionBg, {})] }), _jsx(StyledContent, { children: _jsxs(Stack, { sx: { width: 1 }, children: [" ", children, " "] }) })] }));
}
