var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @mui
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
// components
import { bgBlur } from '@raas/shared/src/utils/cssStyles';
import { NAV } from '@raas/shared/src/shared-config-global';
import Iconify from '@raas/shared/src/components/minimals/iconify';
import { useSettingsContext } from '@raas/shared/src/components/minimals/settings';
// hooks
import useResponsive from '@raas/shared/src/hooks/useResponsive';
// utils
// config
// ----------------------------------------------------------------------
export default function NavToggleButton(_a) {
    var sx = _a.sx, other = __rest(_a, ["sx"]);
    var theme = useTheme();
    var _b = useSettingsContext(), themeLayout = _b.themeLayout, onToggleLayout = _b.onToggleLayout;
    var isDesktop = useResponsive('up', 'lg');
    if (!isDesktop) {
        return null;
    }
    return (_jsx(IconButton, __assign({ size: "small", onClick: onToggleLayout, sx: __assign(__assign(__assign({ p: 0.5, top: 32, position: 'fixed', left: NAV.W_DASHBOARD - 12, zIndex: theme.zIndex.appBar + 1, border: "dashed 1px ".concat(theme.palette.divider) }, bgBlur({ opacity: 0.48, color: theme.palette.background.default })), { '&:hover': {
                bgcolor: 'background.default',
            } }), sx) }, other, { children: _jsx(Iconify, { width: 16, icon: themeLayout === 'vertical' ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill' }) })));
}
