import { useTranslation } from 'react-i18next';
// components
import { useSettingsContext } from '../components/minimals/settings';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { allLangs, defaultLang } from './config-lang';
// ----------------------------------------------------------------------
export default function useLocales() {
    var _a = useTranslation(), i18n = _a.i18n, translate = _a.t;
    var onChangeDirectionByLang = useSettingsContext().onChangeDirectionByLang;
    var storageAvailable = localStorageAvailable();
    var langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';
    var currentLang = allLangs.find(function (_lang) { return _lang.value === langStorage; }) || defaultLang;
    var handleChangeLanguage = function (newlang) {
        i18n.changeLanguage(newlang);
        onChangeDirectionByLang(newlang);
    };
    return {
        onChangeLang: handleChangeLanguage,
        translate: function (text, options) { return "".concat(translate(text, options)); },
        currentLang: currentLang,
        allLangs: allLangs,
    };
}
