var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Avatar } from '@mui/material';
// ----------------------------------------------------------------------
var getCharAtName = function (name) { return name && name.charAt(0).toUpperCase(); };
var getColorByName = function (name) {
    if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name)))
        return 'primary';
    if (['F', 'G', 'T', 'I', 'J'].includes(getCharAtName(name)))
        return 'info';
    if (['K', 'D', 'Y', 'B', 'O'].includes(getCharAtName(name)))
        return 'success';
    if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name)))
        return 'warning';
    if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name)))
        return 'error';
    return 'default';
};
// ----------------------------------------------------------------------
var CustomAvatar = forwardRef(function (_a, ref) {
    var _b, _c;
    var color = _a.color, _d = _a.name, name = _d === void 0 ? '' : _d, BadgeProps = _a.BadgeProps, children = _a.children, sx = _a.sx, other = __rest(_a, ["color", "name", "BadgeProps", "children", "sx"]);
    var theme = useTheme();
    var charAtName = getCharAtName(name);
    var colorByName = getColorByName(name);
    var colr = color || colorByName;
    var renderContent = colr === 'default' ? (_jsxs(Avatar, __assign({ ref: ref, sx: sx }, other, { children: [name && charAtName, children] }))) : (_jsxs(Avatar, __assign({ ref: ref, sx: __assign({ color: (_b = theme.palette[colr]) === null || _b === void 0 ? void 0 : _b.contrastText, backgroundColor: (_c = theme.palette[colr]) === null || _c === void 0 ? void 0 : _c.main, fontWeight: theme.typography.fontWeightMedium }, sx) }, other, { children: [name && charAtName, children] })));
    return BadgeProps ? (_jsx(Badge, __assign({ overlap: "circular", anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }, BadgeProps, { children: renderContent }))) : (renderContent);
});
export default CustomAvatar;
