var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
// utils
import { hideScrollbarY } from '@raas/shared/src/utils/cssStyles';
//
import { isEnabled } from '@raas/shared/src/utils/featureEnableUtils';
import NavList from './NavList';
// ----------------------------------------------------------------------
function NavSectionHorizontal(_a) {
    var data = _a.data, sx = _a.sx, other = __rest(_a, ["data", "sx"]);
    return (_jsx(Stack, __assign({ direction: "row", spacing: 1, sx: __assign(__assign({ mx: 'auto' }, hideScrollbarY), sx) }, other, { children: data.map(function (group) { return (_jsx(Items, { items: group.items }, group.subheader)); }) })));
}
export default memo(NavSectionHorizontal);
function Items(_a) {
    var items = _a.items;
    return (_jsx(_Fragment, { children: items.filter(function (x) { return !x.permissionRequired || isEnabled(x.permissionRequired); }).map(function (list) { return (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children }, list.title + list.path)); }) }));
}
