// @mui
import { enUS } from '@mui/material/locale';
// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------
export var allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
    },
];
export var defaultLang = allLangs[0]; // English
