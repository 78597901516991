var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// theme
import palette from '@raas/shared/src/theme/palette';
// ----------------------------------------------------------------------
var themePalette = palette('light');
export var presets = [
    __assign({ name: 'default' }, themePalette.primary),
    // CYAN
    {
        name: 'cyan',
        lighter: '#CCF4FE',
        light: '#68CDF9',
        main: '#078DEE',
        dark: '#0351AB',
        darker: '#012972',
        contrastText: '#FFFFFF',
    },
    // PURPLE
    {
        name: 'purple',
        lighter: '#EBD6FD',
        light: '#B985F4',
        main: '#7635dc',
        dark: '#431A9E',
        darker: '#200A69',
        contrastText: '#FFFFFF',
    },
    // BLUE
    {
        name: 'blue',
        lighter: '#D1E9FC',
        light: '#76B0F1',
        main: '#2065D1',
        dark: '#103996',
        darker: '#061B64',
        contrastText: '#FFFFFF',
    },
    // ORANGE
    {
        name: 'orange',
        lighter: '#FEF4D4',
        light: '#FED680',
        main: '#fda92d',
        dark: '#B66816',
        darker: '#793908',
        contrastText: themePalette.grey[800],
    },
    // RED
    {
        name: 'red',
        lighter: '#FFE3D5',
        light: '#FFC1AC',
        main: '#FF3030',
        dark: '#B71833',
        darker: '#7A0930',
        contrastText: '#FFFFFF',
    },
];
export var defaultPreset = presets[0];
export var cyanPreset = presets[1];
export var purplePreset = presets[2];
export var bluePreset = presets[3];
export var orangePreset = presets[4];
export var redPreset = presets[5];
export var presetsOption = presets.map(function (color) { return ({
    name: color.name,
    value: color.main,
}); });
export function getPresets(key) {
    return {
        default: defaultPreset,
        cyan: cyanPreset,
        purple: purplePreset,
        blue: bluePreset,
        orange: orangePreset,
        red: redPreset,
    }[key];
}
