var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useContext, useMemo, useCallback } from 'react';
// hooks
import localStorageAvailable from '@raas/shared/src/utils/localStorageAvailable';
import useLocalStorage from '@raas/shared/src/hooks/useLocalStorage';
// utils
//
import { defaultSettings } from './config-setting';
import { defaultPreset, getPresets, presetsOption } from './presets';
// ----------------------------------------------------------------------
var initialState = __assign(__assign({}, defaultSettings), { 
    // Mode
    onToggleMode: function () { }, onChangeMode: function () { }, 
    // Direction
    onToggleDirection: function () { }, onChangeDirection: function () { }, onChangeDirectionByLang: function () { }, 
    // Layout
    onToggleLayout: function () { }, onChangeLayout: function () { }, 
    // Contrast
    onToggleContrast: function () { }, onChangeContrast: function () { }, 
    // Color
    onChangeColorPresets: function () { }, presetsColor: defaultPreset, presetsOption: [], 
    // Stretch
    onToggleStretch: function () { }, 
    // Reset
    onResetSetting: function () { } });
// ----------------------------------------------------------------------
export var SettingsContext = createContext(initialState);
export var useSettingsContext = function () {
    var context = useContext(SettingsContext);
    if (!context)
        throw new Error('useSettingsContext must be use inside SettingsProvider');
    return context;
};
export function SettingsProvider(_a) {
    var children = _a.children;
    var _b = useLocalStorage('settings', defaultSettings), settings = _b[0], setSettings = _b[1];
    var storageAvailable = localStorageAvailable();
    var langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';
    var isArabic = langStorage === 'ar';
    useEffect(function () {
        if (isArabic) {
            onChangeDirectionByLang('ar');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArabic]);
    // Mode
    var onToggleMode = useCallback(function () {
        var themeMode = settings.themeMode === 'light' ? 'dark' : 'light';
        setSettings(__assign(__assign({}, settings), { themeMode: themeMode }));
    }, [setSettings, settings]);
    var onChangeMode = useCallback(function (event) {
        var themeMode = event.target.value;
        setSettings(__assign(__assign({}, settings), { themeMode: themeMode }));
    }, [setSettings, settings]);
    // Direction
    var onToggleDirection = useCallback(function () {
        var themeDirection = settings.themeDirection === 'rtl' ? 'ltr' : 'rtl';
        setSettings(__assign(__assign({}, settings), { themeDirection: themeDirection }));
    }, [setSettings, settings]);
    var onChangeDirection = useCallback(function (event) {
        var themeDirection = event.target.value;
        setSettings(__assign(__assign({}, settings), { themeDirection: themeDirection }));
    }, [setSettings, settings]);
    var onChangeDirectionByLang = useCallback(function (lang) {
        var themeDirection = lang === 'ar' ? 'rtl' : 'ltr';
        setSettings(__assign(__assign({}, settings), { themeDirection: themeDirection }));
    }, [setSettings, settings]);
    // Layout
    var onToggleLayout = useCallback(function () {
        var themeLayout = settings.themeLayout === 'vertical' ? 'mini' : 'vertical';
        setSettings(__assign(__assign({}, settings), { themeLayout: themeLayout }));
    }, [setSettings, settings]);
    var onChangeLayout = useCallback(function (event) {
        var themeLayout = event.target.value;
        setSettings(__assign(__assign({}, settings), { themeLayout: themeLayout }));
    }, [setSettings, settings]);
    // Contrast
    var onToggleContrast = useCallback(function () {
        var themeContrast = settings.themeContrast === 'default' ? 'bold' : 'default';
        setSettings(__assign(__assign({}, settings), { themeContrast: themeContrast }));
    }, [setSettings, settings]);
    var onChangeContrast = useCallback(function (event) {
        var themeContrast = event.target.value;
        setSettings(__assign(__assign({}, settings), { themeContrast: themeContrast }));
    }, [setSettings, settings]);
    // Color
    var onChangeColorPresets = useCallback(function (event) {
        var themeColorPresets = event.target.value;
        setSettings(__assign(__assign({}, settings), { themeColorPresets: themeColorPresets }));
    }, [setSettings, settings]);
    // Stretch
    var onToggleStretch = useCallback(function () {
        var themeStretch = !settings.themeStretch;
        setSettings(__assign(__assign({}, settings), { themeStretch: themeStretch }));
    }, [setSettings, settings]);
    // Reset
    var onResetSetting = useCallback(function () {
        setSettings(defaultSettings);
    }, [setSettings]);
    var memoizedValue = useMemo(function () { return (__assign(__assign({}, settings), { 
        // Mode
        onToggleMode: onToggleMode, onChangeMode: onChangeMode, 
        // Direction
        onToggleDirection: onToggleDirection, onChangeDirection: onChangeDirection, onChangeDirectionByLang: onChangeDirectionByLang, 
        // Layout
        onToggleLayout: onToggleLayout, onChangeLayout: onChangeLayout, 
        // Contrast
        onChangeContrast: onChangeContrast, onToggleContrast: onToggleContrast, 
        // Stretch
        onToggleStretch: onToggleStretch, 
        // Color
        onChangeColorPresets: onChangeColorPresets, presetsOption: presetsOption, presetsColor: getPresets(settings.themeColorPresets), 
        // Reset
        onResetSetting: onResetSetting })); }, [
        settings,
        // Mode
        onToggleMode,
        onChangeMode,
        // Direction
        onToggleDirection,
        onChangeDirection,
        onChangeDirectionByLang,
        // Layout
        onToggleLayout,
        onChangeLayout,
        onChangeContrast,
        // Contrast
        onToggleContrast,
        // Stretch
        onToggleStretch,
        // Color
        onChangeColorPresets,
        // Reset
        onResetSetting,
    ]);
    return _jsx(SettingsContext.Provider, { value: memoizedValue, children: children });
}
