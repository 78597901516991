var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useContext } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';
// components
// config
import { HEADER } from '@raas/shared/src/shared-config-global';
// utils
import { bgBlur } from '@raas/shared/src/utils/cssStyles';
import { NavSectionHorizontal } from '@raas/shared/src/components/minimals/nav-section';
//
import LegalProvisionContext from '@raas/shared/src/contexts/LegalProvisionContext';
import getNavConfig from './config-navigation';
// ----------------------------------------------------------------------
function NavHorizontal() {
    var theme = useTheme();
    var legalProvisionContext = useContext(LegalProvisionContext).legalProvisionContext;
    return (_jsxs(AppBar, { component: "nav", color: "transparent", sx: {
            boxShadow: 0,
            top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        }, children: [_jsx(Toolbar, { sx: __assign({}, bgBlur({
                    color: theme.palette.background.default,
                })), children: _jsx(NavSectionHorizontal, { data: getNavConfig(!!legalProvisionContext) }) }), _jsx(Shadow, {})] }));
}
export default memo(NavHorizontal);
// ----------------------------------------------------------------------
function Shadow(_a) {
    var sx = _a.sx, other = __rest(_a, ["sx"]);
    return (_jsx(Box, __assign({ sx: __assign({ left: 0, right: 0, bottom: 0, height: 24, zIndex: -1, width: 1, m: 'auto', borderRadius: '50%', position: 'absolute', boxShadow: function (theme) { return theme.customShadows.z8; } }, sx) }, other)));
}
