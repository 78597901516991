import { jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import useLocales from './useLocales';
export default function ThemeLocalization(_a) {
    var children = _a.children;
    var outerTheme = useTheme();
    var currentLang = useLocales().currentLang;
    var theme = createTheme(outerTheme, currentLang.systemValue);
    return _jsxs(ThemeProvider, { theme: theme, children: [" ", children, " "] });
}
