import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// i18n
import '@raas/shared/src/locales/i18n';
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// components
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APPLICATIONINSIGHTS_CONNECTION_STRING, APP_REGISTRATION_CLIENT_ID, REDIRECT_URI, TENANT_ID } from '@raas/shared/src/shared-config-global';
import SnackbarProvider from '@raas/shared/src/components/minimals/snackbar';
import ScrollToTop from '@raas/shared/src/components/minimals/scroll-to-top';
import { MotionLazyContainer } from '@raas/shared/src/components/minimals/animate';
import { ThemeSettings, SettingsProvider } from '@raas/shared/src/components/minimals/settings';
import ThemeProvider from '@raas/shared/src/theme';
import ThemeLocalization from '@raas/shared/src/locales';
import { AuthProvider } from '@raas/shared/src/auth/JwtContext';
import { PATH_AUTH } from '@raas/shared/src/routes/paths';
import { MsalProvider } from '@azure/msal-react';
import { getMsalInstance, initializeMsalInstance } from '@raas/shared/src/auth/msalInstance';
import Router from './routes';
// ----------------------------------------------------------------------
if (APPLICATIONINSIGHTS_CONNECTION_STRING) {
    var appInsights = new ApplicationInsights({
        config: {
            connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}
var msalConfig = {
    auth: {
        clientId: APP_REGISTRATION_CLIENT_ID,
        authority: "https://login.microsoftonline.com/".concat(TENANT_ID),
        redirectUri: REDIRECT_URI,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};
initializeMsalInstance(msalConfig);
var msalInstance = getMsalInstance();
export default function App() {
    return (_jsx(AuthProvider, { loginPath: PATH_AUTH.login, requiredPermission: 'INTERNAL_ACCESS', children: _jsx(MsalProvider, { instance: msalInstance, children: _jsx(HelmetProvider, { children: _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: _jsx(SettingsProvider, { children: _jsxs(BrowserRouter, { children: [_jsx(ScrollToTop, {}), _jsx(MotionLazyContainer, { children: _jsx(ThemeProvider, { children: _jsx(ThemeSettings, { children: _jsx(ThemeLocalization, { children: _jsx(SnackbarProvider, { children: _jsx(Router, {}) }) }) }) }) })] }) }) }) }) }) }));
}
