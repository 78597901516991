var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormLabel, FormGroup, FormControl, FormHelperText, FormControlLabel, } from '@mui/material';
export function RHFCheckbox(_a) {
    var name = _a.name, helperText = _a.helperText, other = __rest(_a, ["name", "helperText"]);
    var control = useFormContext().control;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs("div", { children: [_jsx(FormControlLabel, __assign({ control: _jsx(Checkbox, __assign({}, field, { checked: field.value })) }, other)), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))] }));
        } }));
}
export function RHFMultiCheckbox(_a) {
    var row = _a.row, name = _a.name, label = _a.label, options = _a.options, spacing = _a.spacing, helperText = _a.helperText, other = __rest(_a, ["row", "name", "label", "options", "spacing", "helperText"]);
    var control = useFormContext().control;
    var getSelected = function (selectedItems, item) {
        return selectedItems.includes(item)
            ? selectedItems.filter(function (value) { return value !== item; })
            : __spreadArray(__spreadArray([], selectedItems, true), [item], false);
    };
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(FormControl, { component: "fieldset", children: [label && (_jsx(FormLabel, { component: "legend", sx: { typography: 'body2' }, children: label })), _jsx(FormGroup, { sx: __assign(__assign({}, (row && {
                            flexDirection: 'row',
                        })), { '& .MuiFormControlLabel-root': __assign({ '&:not(:last-of-type)': {
                                    mb: spacing || 0,
                                } }, (row && {
                                mr: 0,
                                '&:not(:last-of-type)': {
                                    mr: spacing || 2,
                                },
                            })) }), children: options.map(function (option) { return (_jsx(FormControlLabel, __assign({ control: _jsx(Checkbox, { checked: field.value.includes(option.value), onChange: function () { return field.onChange(getSelected(field.value, option.value)); } }), label: option.label }, other), option.value)); }) }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { mx: 0 }, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))] }));
        } }));
}
