var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
import { kontrol_logo } from '@raas/shared/src/assets/images/image';
var Logo = forwardRef(function (_a, ref) {
    var _b = _a.disabledLink, disabledLink = _b === void 0 ? false : _b, sx = _a.sx, other = __rest(_a, ["disabledLink", "sx"]);
    var logo = (_jsx(Box, __assign({ ref: ref, component: "div", sx: __assign({ height: 40, px: 1.5, display: 'inline-flex' }, sx) }, other, { children: _jsx("img", { style: { objectFit: 'contain' }, src: kontrol_logo, alt: "logo" }) })));
    if (disabledLink) {
        return logo;
    }
    return (_jsx(Link, { component: RouterLink, to: "/", sx: { display: 'contents' }, children: logo }));
});
export default Logo;
