import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Stack, Typography } from '@mui/material';
// auth
// layouts
import LoginLayout from '@raas/shared/src/layouts/login';
//
import { useLocales } from '@raas/shared/src/locales';
import { APP_REGISTRATION_CLIENT_ID } from '@raas/shared/src/shared-config-global';
import AuthLoginForm from './AuthLoginForm';
export default function Login(props) {
    var translate = useLocales().translate;
    var requiredPermission = props.requiredPermission;
    return (_jsxs(LoginLayout, { children: [_jsx(Stack, { spacing: 2, sx: { mb: 5, position: 'relative' }, children: _jsx(Typography, { variant: "h4", children: translate('common.signIn') }) }), _jsx(AuthLoginForm, { requiredPermission: requiredPermission, clientId: APP_REGISTRATION_CLIENT_ID })] }));
}
