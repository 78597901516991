import { jwtDecode } from '@raas/shared/src/auth/utils';
import localStorageAvailable from '@raas/shared/src/utils/localStorageAvailable';
export function isEditEnabled() {
    var storageAvailable = localStorageAvailable();
    var accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
    var _a = jwtDecode(accessToken), roles = _a.roles, hasInternalAccess = _a.hasInternalAccess;
    if (roles) {
        return roles.includes('INTERNAL_ACCESS');
    }
    return hasInternalAccess;
}
export function isEnabled(requiredPermission) {
    var storageAvailable = localStorageAvailable();
    var accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
    var _a = jwtDecode(accessToken), roles = _a.roles, hasInternalAccess = _a.hasInternalAccess, hasExternalAccess = _a.hasExternalAccess, hasLegalRequirementAccess = _a.hasLegalRequirementAccess;
    // TODO asc: can be removed when only internal accesTokens are used
    if (roles) {
        return roles.includes(requiredPermission);
    }
    if (requiredPermission === 'INTERNAL_ACCESS') {
        return hasInternalAccess;
    }
    if (requiredPermission === 'EXTERNAL_ACCESS') {
        return hasExternalAccess;
    }
    if (requiredPermission === 'LEGAL_REQUIREMENTS') {
        return hasLegalRequirementAccess;
    }
    return false;
}
