var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Popover, ListItemButton, ListItemIcon } from '@mui/material';
// utils
import { bgBlur } from '@raas/shared/src/utils/cssStyles';
// config
import { ICON } from '@raas/shared/src/shared-config-global';
export var StyledItem = styled(ListItemButton, {
    shouldForwardProp: function (prop) { return prop !== 'active' && prop !== 'open'; },
})(function (_a) {
    var active = _a.active, disabled = _a.disabled, open = _a.open, depth = _a.depth, theme = _a.theme;
    var isLight = theme.palette.mode === 'light';
    var subItem = depth !== 1;
    var activeStyle = __assign({ color: theme.palette.primary.main, backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) }, (!isLight && {
        color: theme.palette.primary.light,
    }));
    var activeSubStyle = {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
    };
    var hoverStyle = {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.hover,
    };
    return __assign(__assign(__assign(__assign(__assign({ flexDirection: 'column', textTransform: 'capitalize', padding: theme.spacing(1, 0, 0.5, 0), color: theme.palette.text.secondary, borderRadius: theme.shape.borderRadius, '&:hover': hoverStyle }, (subItem && {
        flexDirection: 'row',
        padding: theme.spacing(1),
    })), (active && __assign(__assign({}, activeStyle), { '&:hover': __assign({}, activeStyle) }))), (subItem &&
        active && __assign(__assign({}, activeSubStyle), { '&:hover': __assign({}, activeSubStyle) }))), (open && !active && hoverStyle)), (disabled && {
        '&.Mui-disabled': {
            opacity: 0.64,
        },
    }));
});
// ----------------------------------------------------------------------
export var StyledIcon = styled(ListItemIcon)({
    marginRight: 0,
    marginBottom: 4,
    width: ICON.NAV_ITEM_MINI,
    height: ICON.NAV_ITEM_MINI,
});
// ----------------------------------------------------------------------
export var StyledPopover = styled(Popover)(function (_a) {
    var theme = _a.theme;
    return ({
        pointerEvents: 'none',
        '& .MuiPopover-paper': __assign({ width: 160, pointerEvents: 'auto', padding: theme.spacing(1), marginTop: theme.spacing(0.5), boxShadow: theme.customShadows.dropdown, borderRadius: Number(theme.shape.borderRadius) * 1.5 }, bgBlur({ color: theme.palette.background.default })),
    });
});
