// @mui
import { useTheme } from '@mui/material/styles';
// hooks
import { useWidth } from '@raas/shared/src/hooks/useResponsive';
// theme
import { remToPx } from '@raas/shared/src/theme/typography';
// ----------------------------------------------------------------------
export default function useTypography(variant) {
    var theme = useTheme();
    var breakpoints = useWidth();
    var key = theme.breakpoints.up(breakpoints === 'xl' ? 'lg' : breakpoints);
    var hasResponsive = variant === 'h1' ||
        variant === 'h2' ||
        variant === 'h3' ||
        variant === 'h4' ||
        variant === 'h5' ||
        variant === 'h6';
    var getFont = hasResponsive && theme.typography[variant][key]
        ? theme.typography[variant][key]
        : theme.typography[variant];
    var fontSize = remToPx(getFont.fontSize);
    var lineHeight = Number(theme.typography[variant].lineHeight) * fontSize;
    var _a = theme.typography[variant], fontWeight = _a.fontWeight, letterSpacing = _a.letterSpacing;
    return { fontSize: fontSize, lineHeight: lineHeight, fontWeight: fontWeight, letterSpacing: letterSpacing };
}
