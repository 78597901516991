import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import { HEADER } from '@raas/shared/src/shared-config-global';
import useOffSetTop from '@raas/shared/src/hooks/useOffSetTop';
// config
//
import Header from './Header';
// ----------------------------------------------------------------------
export default function CompactLayout() {
    var isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { isOffset: isOffset }), _jsx(Container, { component: "main", children: _jsx(Stack, { sx: {
                        py: 12,
                        m: 'auto',
                        maxWidth: 400,
                        minHeight: '100vh',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }, children: _jsx(Outlet, {}) }) })] }));
}
