var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @mui
import { styled, alpha } from '@mui/material/styles';
// utils
import { bgGradient } from '@raas/shared/src/utils/cssStyles';
// ----------------------------------------------------------------------
export var StyledRoot = styled('main')(function () { return ({
    height: '100%',
    display: 'flex',
    position: 'relative',
}); });
export var StyledSection = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'none',
            position: 'relative'
        },
        _b[theme.breakpoints.up('md')] = {
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        _b);
});
export var StyledSectionBg = styled('div')(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, bgGradient({
        color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.9 : 0.94),
        imgUrl: '/assets/background/overlay_2.jpg',
    })), { top: 0, left: 0, zIndex: -1, width: '100%', height: '100%', position: 'absolute', transform: 'scaleX(-1)' }));
});
export var StyledContent = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            width: 480,
            margin: 'auto',
            display: 'flex',
            minHeight: '100vh',
            justifyContent: 'center',
            padding: theme.spacing(15, 2)
        },
        _b[theme.breakpoints.up('md')] = {
            flexShrink: 0,
            padding: theme.spacing(30, 8, 0, 8),
        },
        _b);
});
