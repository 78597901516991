var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { List, Stack } from '@mui/material';
// locales
import { useLocales } from '@raas/shared/src/locales';
//
import { isEnabled } from '@raas/shared/src/utils/featureEnableUtils';
import { StyledSubheader } from './styles';
import NavList from './NavList';
// ----------------------------------------------------------------------
export default function NavSectionVertical(_a) {
    var data = _a.data, sx = _a.sx, other = __rest(_a, ["data", "sx"]);
    var translate = useLocales().translate;
    return (_jsx(Stack, __assign({ sx: sx }, other, { children: data.map(function (group) {
            var key = group.subheader || group.items[0].title;
            return (_jsxs(List, { disablePadding: true, sx: { px: 2 }, children: [group.subheader && (_jsx(StyledSubheader, { disableSticky: true, children: "".concat(translate(group.subheader)) })), group.items.filter(function (x) { return !x.permissionRequired || isEnabled(x.permissionRequired); }).map(function (list) { return (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children }, list.title + list.path)); })] }, key));
        }) })));
}
