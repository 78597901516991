import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '@raas/shared/src/components/minimals/loading-screen';
//
import Login from '@raas/shared/src/pages/LoginPage';
import { useAuthContext } from './useAuthContext';
export default function AuthGuard(_a) {
    var children = _a.children, requiredPermission = _a.requiredPermission;
    var _b = useAuthContext(), isAuthenticated = _b.isAuthenticated, isInitialized = _b.isInitialized;
    var pathname = useLocation().pathname;
    var _c = useState(null), requestedLocation = _c[0], setRequestedLocation = _c[1];
    if (!isInitialized) {
        return _jsx(LoadingScreen, {});
    }
    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return _jsx(Login, { requiredPermission: requiredPermission });
    }
    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return _jsx(Navigate, { to: requestedLocation });
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
