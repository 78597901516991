var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Chip, Select, Checkbox, MenuItem, TextField, InputLabel, FormControl, OutlinedInput, FormHelperText, } from '@mui/material';
export function RHFSelect(_a) {
    var name = _a.name, native = _a.native, _b = _a.maxHeight, maxHeight = _b === void 0 ? 220 : _b, helperText = _a.helperText, children = _a.children, other = __rest(_a, ["name", "native", "maxHeight", "helperText", "children"]);
    var control = useFormContext().control;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({}, field, { select: true, fullWidth: true, SelectProps: {
                    native: native,
                    MenuProps: {
                        PaperProps: {
                            sx: __assign({}, (!native && {
                                px: 1,
                                maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                                '& .MuiMenuItem-root': {
                                    px: 1,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize',
                                },
                            })),
                        },
                    },
                    sx: { textTransform: 'capitalize' },
                }, error: !!error, helperText: error ? error === null || error === void 0 ? void 0 : error.message : helperText }, other, { children: children })));
        } }));
}
export function RHFMultiSelect(_a) {
    var name = _a.name, chip = _a.chip, label = _a.label, options = _a.options, checkbox = _a.checkbox, placeholder = _a.placeholder, helperText = _a.helperText, sx = _a.sx, other = __rest(_a, ["name", "chip", "label", "options", "checkbox", "placeholder", "helperText", "sx"]);
    var control = useFormContext().control;
    var renderValues = function (selectedIds) {
        var selectedItems = options.filter(function (item) { return selectedIds.includes(item.value); });
        if (!selectedItems.length && placeholder) {
            return (_jsx(Box, { component: "em", sx: { color: 'text.disabled' }, children: placeholder }));
        }
        if (chip) {
            return (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: selectedItems.map(function (item) { return (_jsx(Chip, { size: "small", label: item.label }, item.value)); }) }));
        }
        return selectedItems.map(function (item) { return item.label; }).join(', ');
    };
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(FormControl, { sx: sx, children: [label && _jsxs(InputLabel, { id: name, children: [" ", label, " "] }), _jsxs(Select, __assign({}, field, { multiple: true, displayEmpty: !!placeholder, labelId: name, input: _jsx(OutlinedInput, { fullWidth: true, label: label, error: !!error }), renderValue: renderValues, MenuProps: {
                            PaperProps: {
                                sx: { px: 1, maxHeight: 280 },
                            },
                        } }, other, { children: [placeholder && (_jsx(MenuItem, { disabled: true, value: "", sx: {
                                    py: 1,
                                    px: 2,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                }, children: _jsxs("em", { children: [" ", placeholder, " "] }) })), options.map(function (option) {
                                var selected = field.value.includes(option.value);
                                return (_jsxs(MenuItem, { value: option.value, sx: __assign(__assign({ py: 1, px: 2, borderRadius: 0.75, typography: 'body2' }, (selected && {
                                        fontWeight: 'fontWeightMedium',
                                    })), (checkbox && {
                                        p: 0.25,
                                    })), children: [checkbox && _jsx(Checkbox, { disableRipple: true, size: "small", checked: selected }), option.label] }, option.value));
                            })] })), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))] }));
        } }));
}
