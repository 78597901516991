var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Link } from '@mui/material';
// components
// config
import { HEADER } from '@raas/shared/src/shared-config-global';
// utils
import { bgBlur } from '@raas/shared/src/utils/cssStyles';
import Logo from '@raas/shared/src/components/minimals/logo';
export default function Header(_a) {
    var isOffset = _a.isOffset;
    var theme = useTheme();
    return (_jsxs(AppBar, { color: "transparent", sx: { boxShadow: 0 }, children: [_jsxs(Toolbar, { sx: __assign({ justifyContent: 'space-between', height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_MAIN_DESKTOP,
                    }, transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }) }, (isOffset && __assign(__assign({}, bgBlur({ color: theme.palette.background.default })), { height: {
                        md: HEADER.H_MAIN_DESKTOP - 16,
                    } }))), children: [_jsx(Logo, {}), _jsx(Link, { variant: "subtitle2", color: "inherit", children: "Need Help?" })] }), isOffset && _jsx(Shadow, {})] }));
}
// ----------------------------------------------------------------------
function Shadow(_a) {
    var sx = _a.sx, other = __rest(_a, ["sx"]);
    return (_jsx(Box, __assign({ sx: __assign({ left: 0, right: 0, bottom: 0, height: 24, zIndex: -1, m: 'auto', borderRadius: '50%', position: 'absolute', width: "calc(100% - 48px)", boxShadow: function (theme) { return theme.customShadows.z8; } }, sx) }, other)));
}
