import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// hooks
import useActiveLink from '@raas/shared/src/hooks/useActiveLink';
import { StyledPopover } from './styles';
import NavItem from './NavItem';
export default function NavList(_a) {
    var data = _a.data, depth = _a.depth, hasChild = _a.hasChild;
    var navRef = useRef(null);
    var pathname = useLocation().pathname;
    var _b = useActiveLink(data.path), active = _b.active, isExternalLink = _b.isExternalLink;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    useEffect(function () {
        if (open) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    useEffect(function () {
        var appBarEl = Array.from(document.querySelectorAll('.MuiAppBar-root'));
        // Reset styles when hover
        var styles = function () {
            document.body.style.overflow = '';
            document.body.style.padding = '';
            // Apply for Window
            appBarEl.forEach(function (elem) {
                elem.style.padding = '';
            });
        };
        if (open) {
            styles();
        }
        else {
            styles();
        }
    }, [open]);
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { ref: navRef, item: data, depth: depth, open: open, active: active, isExternalLink: isExternalLink, onMouseEnter: handleOpen, onMouseLeave: handleClose }), hasChild && (_jsx(StyledPopover, { open: open, anchorEl: navRef.current, anchorOrigin: depth === 1
                    ? { vertical: 'bottom', horizontal: 'left' }
                    : { vertical: 'center', horizontal: 'right' }, transformOrigin: depth === 1
                    ? { vertical: 'top', horizontal: 'left' }
                    : { vertical: 'center', horizontal: 'left' }, PaperProps: {
                    onMouseEnter: handleOpen,
                    onMouseLeave: handleClose,
                }, children: _jsx(NavSubList, { data: data.children, depth: depth }) }))] }));
}
function NavSubList(_a) {
    var data = _a.data, depth = _a.depth;
    return (_jsx(_Fragment, { children: data.map(function (list) { return (_jsx(NavList, { data: list, depth: depth + 1, hasChild: !!list.children }, list.title + list.path)); }) }));
}
