import { PublicClientApplication } from '@azure/msal-browser';
var msalInstance = null;
var initializeMsalInstance = function (config) {
    if (!msalInstance) {
        msalInstance = new PublicClientApplication(config);
    }
};
var getMsalInstance = function () {
    if (!msalInstance) {
        throw new Error('MSAL instance has not been initialized. Call initializeMsalInstance with a configuration.');
    }
    return msalInstance;
};
export { initializeMsalInstance, getMsalInstance };
