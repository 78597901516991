import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
// components
import LoadingScreen from '@raas/shared/src/components/minimals/loading-screen';
//
import { useAuthContext } from './useAuthContext';
export default function GuestGuard(_a) {
    var children = _a.children;
    var _b = useAuthContext(), isAuthenticated = _b.isAuthenticated, isInitialized = _b.isInitialized;
    if (isAuthenticated) {
        return _jsx(Navigate, { to: "/dashboard" });
    }
    if (!isInitialized) {
        return _jsx(LoadingScreen, {});
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
