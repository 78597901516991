// API
// ----------------------------------------------------------------------
export var HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export var BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export var APPLICATIONINSIGHTS_CONNECTION_STRING = process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING || '';
export var APP_REGISTRATION_CLIENT_ID = process.env.REACT_APP_APP_REGISTRATION_CLIENT_ID || '';
export var TENANT_ID = process.env.REACT_APP_TENANT_ID || '';
export var REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || '';
if (!BACKEND_URL) {
    throw new Error('Environment variable REACT_APP_BACKEND_URL must be set.');
}
export var DATE_FORMAT = 'yyyy-MM-dd';
export var DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
// LAYOUT
// ----------------------------------------------------------------------
export var HEADER = {
    H_MOBILE: 64,
    H_MAIN_DESKTOP: 88,
    H_DASHBOARD_DESKTOP: 92,
    H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};
export var FOOTER = {
    H_MAIN_DESKTOP: 94,
};
export var NAV = {
    W_BASE: 260,
    W_LARGE: 320,
    W_DASHBOARD: 280,
    W_DASHBOARD_MINI: 88,
    //
    H_DASHBOARD_ITEM: 48,
    H_DASHBOARD_ITEM_SUB: 36,
    //
    H_DASHBOARD_ITEM_HORIZONTAL: 32,
};
export var ICON = {
    NAV_ITEM: 24,
    NAV_ITEM_HORIZONTAL: 22,
    NAV_ITEM_MINI: 22,
};
export var PAGINATION_ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 200];
export var PAGINATION_ROWS_PER_PAGE_DEFAULT = 200;
