import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Card } from '@mui/material';
export default function EnvironmentInformation() {
    var stage = process.env.REACT_APP_ENV;
    if (!stage) {
        stage = 'Development';
    }
    if (stage !== 'Demo' && stage !== 'Production') {
        return (_jsx(Card, { sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
                paddingLeft: '30px',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                bgcolor: 'transparent',
                boxShadow: 'none',
            }, children: _jsxs("div", { style: { color: 'red' }, children: [stage, " Environment"] }) }));
    }
    return _jsx("div", {});
}
