var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
// components
import { CustomAvatar } from '@raas/shared/src/components/minimals/custom-avatar';
import { useSnackbar } from '@raas/shared/src/components/minimals/snackbar';
import MenuPopover from '@raas/shared/src/components/minimals/menu-popover';
import { IconButtonAnimate } from '@raas/shared/src/components/minimals/animate';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '@raas/shared/src/routes/paths';
// auth
import { useAuthContext } from '@raas/shared/src/auth/useAuthContext';
export default function AccountPopover() {
    var _this = this;
    var navigate = useNavigate();
    var _a = useAuthContext(), user = _a.user, logout = _a.logout;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _b = useState(null), openPopover = _b[0], setOpenPopover = _b[1];
    var handleOpenPopover = function (event) {
        setOpenPopover(event.currentTarget);
    };
    var handleClosePopover = function () {
        setOpenPopover(null);
    };
    var handleChangePassword = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleClosePopover();
            navigate(PATH_DASHBOARD.user);
            return [2 /*return*/];
        });
    }); };
    function isKontrolUser() {
        return user.userType === 'Kontrol';
    }
    var handleLogout = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                logout();
                navigate(PATH_AUTH.login, { replace: true });
                handleClosePopover();
            }
            catch (error) {
                console.error(error);
                enqueueSnackbar('Unable to logout!', { variant: 'error' });
            }
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(IconButtonAnimate, { onClick: handleOpenPopover, sx: __assign({ p: 0 }, (openPopover && {
                    '&:before': {
                        zIndex: 1,
                        content: "''",
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        position: 'absolute',
                        bgcolor: function (theme) { return alpha(theme.palette.grey[900], 0.8); },
                    },
                })), children: _jsx(CustomAvatar, { alt: user === null || user === void 0 ? void 0 : user.displayName, name: user === null || user === void 0 ? void 0 : user.displayName }) }), _jsxs(MenuPopover, { open: openPopover, onClose: handleClosePopover, sx: { width: 200, p: 0 }, children: [_jsxs(Box, { sx: { my: 1.5, px: 2.5 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: user === null || user === void 0 ? void 0 : user.displayName }), _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, noWrap: true, children: user === null || user === void 0 ? void 0 : user.email })] }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(MenuItem, { onClick: handleChangePassword, sx: { m: 1 }, disabled: !isKontrolUser(), children: "Change Password" }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(MenuItem, { onClick: handleLogout, sx: { m: 1 }, children: "Logout" })] })] }));
}
