import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '@raas/shared/src/hooks/useActiveLink';
import NavItem from './NavItem';
export default function NavList(_a) {
    var data = _a.data, depth = _a.depth, hasChild = _a.hasChild;
    var pathname = useLocation().pathname;
    var _b = useActiveLink(data.path), active = _b.active, isExternalLink = _b.isExternalLink;
    var _c = useState(active), open = _c[0], setOpen = _c[1];
    useEffect(function () {
        if (!active) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    var handleToggle = function () {
        setOpen(!open);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { item: data, depth: depth, open: open, active: active, isExternalLink: isExternalLink, onClick: handleToggle }), hasChild && (_jsx(Collapse, { in: open, unmountOnExit: true, children: _jsx(NavSubList, { data: data.children, depth: depth }) }))] }));
}
function NavSubList(_a) {
    var data = _a.data, depth = _a.depth;
    return (_jsx(_Fragment, { children: data.map(function (list) { return (_jsx(NavList, { data: list, depth: depth + 1, hasChild: !!list.children }, list.title + list.path)); }) }));
}
