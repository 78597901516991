var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, Link, ListItemText } from '@mui/material';
// locales
import RoleBasedGuard from '@raas/shared/src/auth/RoleBasedGuard';
import { useLocales } from '@raas/shared/src/locales';
// auth
//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
// ----------------------------------------------------------------------
export default function NavItem(_a) {
    var item = _a.item, depth = _a.depth, open = _a.open, active = _a.active, isExternalLink = _a.isExternalLink, other = __rest(_a, ["item", "depth", "open", "active", "isExternalLink"]);
    var translate = useLocales().translate;
    var title = item.title, path = item.path, icon = item.icon, info = item.info, children = item.children, disabled = item.disabled, caption = item.caption, roles = item.roles;
    var subItem = depth !== 1;
    var renderContent = (_jsxs(StyledItem, __assign({ depth: depth, active: active, disabled: disabled, caption: !!caption }, other, { children: [icon && _jsx(StyledIcon, { children: icon }), subItem && (_jsx(StyledIcon, { children: _jsx(StyledDotIcon, { active: active && subItem }) })), _jsx(ListItemText, { primary: "".concat(translate(title)), secondary: caption && (_jsx(Tooltip, { title: "".concat(translate(caption)), placement: "top-start", children: _jsx("span", { children: "".concat(translate(caption)) }) })), primaryTypographyProps: {
                    noWrap: true,
                    component: 'span',
                    variant: active ? 'subtitle2' : 'body2',
                }, secondaryTypographyProps: {
                    noWrap: true,
                    variant: 'caption',
                } }), info && (_jsx(Box, { component: "span", sx: { lineHeight: 0 }, children: info })), !!children && (_jsx(Iconify, { width: 16, icon: open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill', sx: { ml: 1, flexShrink: 0 } }))] })));
    var renderItem = function () {
        // ExternalLink
        if (isExternalLink)
            return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", underline: "none", children: renderContent }));
        // Has child
        if (children) {
            return renderContent;
        }
        // Default
        return (_jsx(Link, { component: RouterLink, to: path, underline: "none", children: renderContent }));
    };
    return _jsxs(RoleBasedGuard, { roles: roles, children: [" ", renderItem(), " "] });
}
