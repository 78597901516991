var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { bgBlur } from '@raas/shared/src/utils/cssStyles';
import { HEADER, NAV } from '@raas/shared/src/shared-config-global';
import AccountPopover from '@raas/shared/src/components/header/AccountPopover';
import Logo from '@raas/shared/src/components/minimals/logo';
import Iconify from '@raas/shared/src/components/minimals/iconify';
import { useSettingsContext } from '@raas/shared/src/components/minimals/settings';
// utils
// hooks
import useOffSetTop from '@raas/shared/src/hooks/useOffSetTop';
import useResponsive from '@raas/shared/src/hooks/useResponsive';
import EnvironmentInformation from './EnvironmentInformation';
export default function Header(_a) {
    var onOpenNav = _a.onOpenNav;
    var theme = useTheme();
    var themeLayout = useSettingsContext().themeLayout;
    var isNavHorizontal = themeLayout === 'horizontal';
    var isNavMini = themeLayout === 'mini';
    var isDesktop = useResponsive('up', 'lg');
    var isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
    var renderContent = (_jsxs(_Fragment, { children: [isDesktop && isNavHorizontal && _jsx(Logo, { sx: { mr: 2.5 } }), !isDesktop && (_jsx(IconButton, { onClick: onOpenNav, sx: { mr: 1, color: 'text.primary' }, children: _jsx(Iconify, { icon: "eva:menu-2-fill" }) })), _jsx(Stack, { flexGrow: 1, direction: "row", alignItems: "center", justifyContent: "flex-end", spacing: { xs: 0.5, sm: 1.5 }, children: _jsx(AccountPopover, {}) })] }));
    return (_jsxs(AppBar, { sx: __assign(__assign(__assign({ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxShadow: 'none', height: HEADER.H_MOBILE, zIndex: theme.zIndex.appBar + 1 }, bgBlur({
            color: theme.palette.background.default,
        })), { transition: theme.transitions.create(['height'], {
                duration: theme.transitions.duration.shorter,
            }) }), (isDesktop && __assign(__assign(__assign({ width: "calc(100% - ".concat(NAV.W_DASHBOARD + 1, "px)"), height: HEADER.H_DASHBOARD_DESKTOP }, (isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        })), (isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: "dashed 1px ".concat(theme.palette.divider),
        })), (isNavMini && {
            width: "calc(100% - ".concat(NAV.W_DASHBOARD_MINI + 1, "px)"),
        })))), children: [_jsx(EnvironmentInformation, {}), _jsx(Toolbar, { sx: {
                    height: 1,
                    px: { lg: 5 },
                }, children: renderContent })] }));
}
