import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Box, CardContent } from '@mui/material';
import LegalProvisionContext from '@raas/shared/src/contexts/LegalProvisionContext';
import { useLocales } from '@raas/shared/src/locales';
import TextMaxLine from '../minimals/text-max-line';
export default function SelectedLegalProvision() {
    var legalProvisionContext = useContext(LegalProvisionContext).legalProvisionContext;
    var translate = useLocales().translate;
    return (_jsx(Box, { sx: { display: 'flex', flexDirection: 'row', flexGrow: 1 }, children: _jsx(CardContent, { sx: {
                flex: '1 0 auto',
                padding: 0,
                pl: 1,
                width: 'fit-content',
                flexDirection: 'row',
                alignSelf: 'center',
                '&:last-child': { pb: 0 },
            }, children: legalProvisionContext && (_jsxs(_Fragment, { children: [_jsx(TextMaxLine, { line: 2, sx: { fontWeight: 'bold', maxWidth: '175px' }, variant: "body2", children: legalProvisionContext.fullName }), _jsx(TextMaxLine, { line: 1, color: "text.secondary", variant: "caption", children: "".concat(translate('versions.versionName'), ": ").concat(legalProvisionContext.versionName) }), (legalProvisionContext === null || legalProvisionContext === void 0 ? void 0 : legalProvisionContext.releaseTimestamp) && (_jsx(TextMaxLine, { line: 1, color: "text.secondary", variant: "caption", children: "".concat(translate('versions.releaseDate'), ": ").concat(legalProvisionContext.releaseTimestamp.toDateString()) }))] })) }) }));
}
