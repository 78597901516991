var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { m } from 'framer-motion';
import { useLocation } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, LinearProgress } from '@mui/material';
// hooks
import { NAV, HEADER } from '@raas/shared/src/shared-config-global';
import useResponsive from '@raas/shared/src/hooks/useResponsive';
// config
// auth
import { useAuthContext } from '@raas/shared/src/auth/useAuthContext';
//
import Logo from '../logo';
import ProgressBar from '../progress-bar';
import { useSettingsContext } from '../settings';
// ----------------------------------------------------------------------
var StyledRoot = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        right: 0,
        bottom: 0,
        zIndex: 9998,
        width: '100%',
        height: '100%',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
    });
});
// ----------------------------------------------------------------------
export default function LoadingScreen() {
    var pathname = useLocation().pathname;
    var isDesktop = useResponsive('up', 'lg');
    var isInitialized = useAuthContext().isInitialized;
    var themeLayout = useSettingsContext().themeLayout;
    var isDashboard = isInitialized && pathname.includes('/dashboard') && isDesktop;
    var size = (themeLayout === 'mini' && NAV.W_DASHBOARD_MINI) ||
        (themeLayout === 'vertical' && NAV.W_DASHBOARD) ||
        144;
    return (_jsxs(_Fragment, { children: [_jsx(ProgressBar, {}), _jsx(StyledRoot, { sx: __assign({}, (isDashboard && __assign({ width: "calc(100% - ".concat(size, "px)"), height: "calc(100% - ".concat(HEADER.H_DASHBOARD_DESKTOP, "px)") }, (themeLayout === 'horizontal' && {
                    width: 1,
                    height: "calc(100% - ".concat(size, "px)"),
                })))), children: isDashboard ? (_jsx(LinearProgress, { color: "inherit", sx: { width: 1, maxWidth: 360 } })) : (_jsxs(_Fragment, { children: [_jsx(m.div, { animate: {
                                scale: [1, 0.9, 0.9, 1, 1],
                                opacity: [1, 0.48, 0.48, 1, 1],
                            }, transition: {
                                duration: 2,
                                ease: 'easeInOut',
                                repeatDelay: 1,
                                repeat: Infinity,
                            }, children: _jsx(Logo, { disabledLink: true, sx: { width: 64, height: 64 } }) }), _jsx(Box, { component: m.div, animate: {
                                scale: [1.6, 1, 1, 1.6, 1.6],
                                rotate: [270, 0, 0, 270, 270],
                                opacity: [0.25, 1, 1, 1, 0.25],
                                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                            }, transition: { ease: 'linear', duration: 3.2, repeat: Infinity }, sx: {
                                width: 100,
                                height: 100,
                                position: 'absolute',
                                border: function (theme) { return "solid 3px ".concat(alpha(theme.palette.primary.dark, 0.24)); },
                            } }), _jsx(Box, { component: m.div, animate: {
                                scale: [1, 1.2, 1.2, 1, 1],
                                rotate: [0, 270, 270, 0, 0],
                                opacity: [1, 0.25, 0.25, 0.25, 1],
                                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                            }, transition: {
                                ease: 'linear',
                                duration: 3.2,
                                repeat: Infinity,
                            }, sx: {
                                width: 120,
                                height: 120,
                                position: 'absolute',
                                border: function (theme) { return "solid 8px ".concat(alpha(theme.palette.primary.dark, 0.24)); },
                            } })] })) })] }));
}
