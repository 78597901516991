var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import { openDB } from 'idb';
import localStorageAvailable from '@raas/shared/src/utils/localStorageAvailable';
//
import { useLocales } from '@raas/shared/src/locales';
import usersService from '@raas/shared/src/service/api/UsersService';
import { isValidToken, setSession, mapUser, tokenRefresh, jwtDecode } from './utils';
import { clearCache } from './serviceWorker';
// ----------------------------------------------------------------------
// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...
// ----------------------------------------------------------------------
var Types;
(function (Types) {
    Types["INITIAL"] = "INITIAL";
    Types["LOGIN"] = "LOGIN";
    Types["REGISTER"] = "REGISTER";
    Types["LOGOUT"] = "LOGOUT";
})(Types || (Types = {}));
// ----------------------------------------------------------------------
var initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
};
var reducer = function (state, action) {
    if (action.type === Types.INITIAL) {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGIN) {
        return __assign(__assign({}, state), { isAuthenticated: true, user: action.payload.user });
    }
    if (action.type === Types.REGISTER) {
        return __assign(__assign({}, state), { isAuthenticated: true, user: action.payload.user });
    }
    if (action.type === Types.LOGOUT) {
        return __assign(__assign({}, state), { isAuthenticated: false, user: null });
    }
    return state;
};
// ----------------------------------------------------------------------
export var AuthContext = createContext(null);
// ----------------------------------------------------------------------
var setIdbForServiceWorker = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var db, tx, objectStore, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, openDB('appDB', 1, {
                    upgrade: function (upgradeDb) {
                        console.debug('Upgrade callback');
                        if (!upgradeDb.objectStoreNames.contains('token')) {
                            console.debug('create store');
                            upgradeDb.createObjectStore('token', { keyPath: 'key' });
                        }
                    },
                })];
            case 1:
                db = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, 5, 6]);
                tx = db.transaction('token', 'readwrite');
                objectStore = tx.objectStore('token');
                return [4 /*yield*/, objectStore.put({ key: 'token', token: token })];
            case 3:
                _a.sent();
                tx.commit();
                console.debug('Updated token store');
                return [3 /*break*/, 6];
            case 4:
                error_1 = _a.sent();
                console.error('Error during transaction:', error_1);
                return [3 /*break*/, 6];
            case 5:
                if (db) {
                    try {
                        db.close();
                        console.debug('Database connection closed successfully');
                    }
                    catch (error) {
                        console.error('Error while closing the database connection:', error);
                    }
                }
                return [7 /*endfinally*/];
            case 6: return [2 /*return*/];
        }
    });
}); };
export function AuthProvider(_a) {
    var _this = this;
    var children = _a.children, loginPath = _a.loginPath, requiredPermission = _a.requiredPermission;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var storageAvailable = localStorageAvailable();
    var translate = useLocales().translate;
    var initialize = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var accessToken, user, profile, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
                    user = void 0;
                    if (!(accessToken && isValidToken(accessToken))) return [3 /*break*/, 2];
                    setSession(accessToken, loginPath, requiredPermission, true);
                    setIdbForServiceWorker(accessToken);
                    return [4 /*yield*/, usersService.getProfile()];
                case 1:
                    profile = _a.sent();
                    user = mapUser(profile);
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            isAuthenticated: true,
                            user: user,
                        },
                    });
                    return [3 /*break*/, 3];
                case 2:
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [storageAvailable, loginPath, requiredPermission]);
    useEffect(function () {
        initialize();
    }, [initialize]);
    // LOGIN
    var login = useCallback(function (email, password) { return __awaiter(_this, void 0, void 0, function () {
        var _a, accessToken, user, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, usersService.login(email, password)];
                case 1:
                    _a = _c.sent(), accessToken = _a.accessToken, user = _a.user;
                    setSession(accessToken, loginPath, requiredPermission, true);
                    setIdbForServiceWorker(accessToken);
                    dispatch({
                        type: Types.LOGIN,
                        payload: {
                            user: mapUser(user),
                        },
                    });
                    return [3 /*break*/, 3];
                case 2:
                    _b = _c.sent();
                    throw new Error(translate('common.invalidCredentials'));
                case 3: return [2 /*return*/];
            }
        });
    }); }, [translate, loginPath, requiredPermission]);
    // LOGOUT
    var logout = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSession(null, loginPath, undefined, false);
            dispatch({
                type: Types.LOGOUT,
            });
            // Deleting database is slower than resetting token which can cause a race condition that causes HTTP 401 on images.
            setIdbForServiceWorker('');
            clearServiceWorkerCache();
            return [2 /*return*/];
        });
    }); }, [loginPath]);
    var loginWithAccessToken = useCallback(function (token) { return __awaiter(_this, void 0, void 0, function () {
        var _a, accessToken, user, exp, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, usersService.createOrLogin(token)];
                case 1:
                    _a = _c.sent(), accessToken = _a.accessToken, user = _a.user;
                    setSession(accessToken, loginPath, requiredPermission, false);
                    exp = jwtDecode(token).exp;
                    tokenRefresh(exp, loginPath, requiredPermission, setIdbForServiceWorker, logout);
                    setIdbForServiceWorker(accessToken);
                    dispatch({
                        type: Types.LOGIN,
                        payload: {
                            user: mapUser(user),
                        },
                    });
                    return [3 /*break*/, 3];
                case 2:
                    _b = _c.sent();
                    throw new Error(translate('common.invalidCredentials'));
                case 3: return [2 /*return*/];
            }
        });
    }); }, [translate, loginPath, requiredPermission, logout]);
    // REGISTER
    var register = useCallback(function (email, password, firstName, lastName) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // ToDo: implement when required
            dispatch({
                type: Types.REGISTER,
                payload: {
                    user: null,
                },
            });
            return [2 /*return*/];
        });
    }); }, []);
    // CHANGE PASSWORD
    var changePassword = useCallback(function (email, password, newPassword) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, usersService.changePassword(email, password, newPassword)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var memoizedValue = useMemo(function () { return ({
        isInitialized: state.isInitialized,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        method: 'jwt',
        login: login,
        loginWithGoogle: function () { },
        loginWithGithub: function () { },
        loginWithTwitter: function () { },
        loginWithAccessToken: loginWithAccessToken,
        register: register,
        changePassword: changePassword,
        logout: logout,
    }); }, [state.isInitialized, state.isAuthenticated, state.user, login, loginWithAccessToken, register, changePassword, logout]);
    return _jsx(AuthContext.Provider, { value: memoizedValue, children: children });
}
function clearServiceWorkerCache() {
    clearCache();
}
