import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '@raas/shared/src/auth/AuthGuard';
import GuestGuard from '@raas/shared/src/auth/GuestGuard';
// layouts
import CompactLayout from '@raas/shared/src/layouts/compact';
import { LegalProvisionContextProvider } from '@raas/shared/src/contexts/LegalProvisionContextProvider';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import { DocumentElementCreatePage, DocumentElementEditPage, LegalProvisionCreatePage, LegalProvisionDetailsPage, LegalProvisionEditPage, LegalProvisionNewVersionPage, LegalProvisionListPage, LegalProvisionAccessPage, LegalRequirementCreatePage, LegalRequirementEditPage, LegalRequirementListPage, LoginPage, Page404, PropertyCreatePage, PropertyEditPage, PropertyListPage, SentenceFragmentsPage, TechnicalRequirementCreatePage, TechnicalRequirementEditPage, InputListPage, InputEditPage, InputCreatePage, TagGroupListPage, TagGroupEditPage, TagGroupCreatePage, TagListPage, TagEditPage, TagCreatePage, ReleaseCreatePage, TechnicalRequirementListPage, UserChangePassword, AlertsEditPage, AlertsListPage, ExportsListPage, ExportsEditPage, ReportsListPage, ImportPage, } from './elements';
// ----------------------------------------------------------------------
export default function Router() {
    return useRoutes([
        {
            path: '/',
            children: [
                { element: _jsx(Navigate, { to: PATH_AFTER_LOGIN, replace: true }), index: true },
                {
                    path: 'login',
                    element: (_jsx(GuestGuard, { children: _jsx(LoginPage, { requiredPermission: "INTERNAL_ACCESS" }) })),
                },
            ],
        },
        {
            path: '/dashboard',
            element: (_jsx(AuthGuard, { requiredPermission: "INTERNAL_ACCESS", children: _jsx(LegalProvisionContextProvider, { requiredPermission: "INTERNAL_ACCESS", children: _jsx(DashboardLayout, {}) }) })),
            children: [
                { element: _jsx(Navigate, { to: PATH_AFTER_LOGIN, replace: true }), index: true },
                {
                    path: 'legal-provision',
                    children: [
                        { element: _jsx(Navigate, { to: "/dashboard/legal-provision/list", replace: true }), index: true },
                        { path: 'list', element: _jsx(LegalProvisionListPage, {}) },
                        { path: 'new', element: _jsx(LegalProvisionCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(LegalProvisionEditPage, {}) },
                        { path: ':id/new', element: _jsx(LegalProvisionNewVersionPage, {}) },
                        { path: ':id/details', element: _jsx(LegalProvisionDetailsPage, {}) },
                        { path: ':entityId/manage-access', element: _jsx(LegalProvisionAccessPage, {}) },
                    ],
                },
                {
                    path: 'document-element',
                    children: [
                        { path: 'new', element: _jsx(DocumentElementCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(DocumentElementEditPage, {}) },
                    ],
                },
                {
                    path: 'sentence-fragments/:id',
                    element: _jsx(SentenceFragmentsPage, {}),
                },
                {
                    path: 'legal-requirement',
                    children: [
                        { element: _jsx(Navigate, { to: "/dashboard/legal-requirement/list", replace: true }), index: true },
                        { path: 'list', element: _jsx(LegalRequirementListPage, {}) },
                        { path: 'new', element: _jsx(LegalRequirementCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(LegalRequirementEditPage, {}) },
                    ],
                },
                {
                    path: 'property',
                    children: [
                        { element: _jsx(Navigate, { to: "/dashboard/property/list", replace: true }), index: true },
                        { path: 'list', element: _jsx(PropertyListPage, {}) },
                        { path: 'new', element: _jsx(PropertyCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(PropertyEditPage, {}) },
                    ],
                },
                {
                    path: 'technical-requirement',
                    children: [
                        {
                            element: _jsx(Navigate, { to: "/dashboard/technical-requirement/list", replace: true }),
                            index: true,
                        },
                        { path: 'list', element: _jsx(TechnicalRequirementListPage, {}) },
                        { path: 'new', element: _jsx(TechnicalRequirementCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(TechnicalRequirementEditPage, {}) },
                    ],
                },
                {
                    path: 'input',
                    children: [
                        {
                            element: _jsx(Navigate, { to: "/dashboard/input/list", replace: true }),
                            index: true,
                        },
                        { path: 'list', element: _jsx(InputListPage, {}) },
                        { path: 'new', element: _jsx(InputCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(InputEditPage, {}) },
                    ],
                },
                {
                    path: 'tag-group',
                    children: [
                        {
                            element: _jsx(Navigate, { to: "/dashboard/tag-group/list", replace: true }),
                            index: true,
                        },
                        { path: 'list', element: _jsx(TagGroupListPage, {}) },
                        { path: 'new', element: _jsx(TagGroupCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(TagGroupEditPage, {}) },
                    ],
                },
                {
                    path: 'tag',
                    children: [
                        {
                            element: _jsx(Navigate, { to: "/dashboard/tag/list", replace: true }),
                            index: true,
                        },
                        { path: 'list', element: _jsx(TagListPage, {}) },
                        { path: 'new', element: _jsx(TagCreatePage, {}) },
                        { path: ':id/edit', element: _jsx(TagEditPage, {}) },
                    ],
                },
                {
                    path: 'release',
                    children: [{ path: ':id/new', element: _jsx(ReleaseCreatePage, {}) }],
                },
                {
                    path: 'user',
                    children: [{ path: 'account', element: _jsx(UserChangePassword, {}) }],
                },
                {
                    path: 'alerts',
                    children: [
                        {
                            path: 'list',
                            element: _jsx(AlertsListPage, {}),
                        },
                        {
                            path: 'new',
                            element: _jsx(AlertsEditPage, { create: true }),
                        },
                        {
                            path: ':id/edit',
                            element: _jsx(AlertsEditPage, { create: false }),
                        },
                    ],
                },
                {
                    path: 'exports',
                    children: [
                        {
                            path: 'list',
                            element: _jsx(ExportsListPage, {}),
                        },
                        {
                            path: 'new',
                            element: _jsx(ExportsEditPage, { create: true }),
                        },
                        {
                            path: ':id/edit',
                            element: _jsx(ExportsEditPage, { create: false }),
                        },
                    ],
                },
                {
                    path: 'reports',
                    children: [
                        {
                            path: 'list',
                            element: _jsx(ReportsListPage, {}),
                        },
                    ],
                },
                {
                    path: 'import',
                    element: _jsx(ImportPage, {}),
                },
            ],
        },
        {
            element: _jsx(CompactLayout, {}),
            children: [{ path: '404', element: _jsx(Page404, {}) }],
        },
        { path: '*', element: _jsx(Navigate, { to: "/404", replace: true }) },
    ]);
}
