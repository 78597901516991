var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Stack, Box } from '@mui/material';
// components
import { useContext } from 'react';
import { NAV } from '@raas/shared/src/shared-config-global';
import { hideScrollbarX } from '@raas/shared/src/utils/cssStyles';
import Logo from '@raas/shared/src/components/minimals/logo';
import { NavSectionMini } from '@raas/shared/src/components/minimals/nav-section';
// config
// utils
//
import LegalProvisionContext from '@raas/shared/src/contexts/LegalProvisionContext';
import getNavConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';
// ----------------------------------------------------------------------
export default function NavMini() {
    var legalProvisionContext = useContext(LegalProvisionContext).legalProvisionContext;
    return (_jsxs(Box, { component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD_MINI },
        }, children: [_jsx(NavToggleButton, { sx: {
                    top: 22,
                    left: NAV.W_DASHBOARD_MINI - 12,
                } }), _jsxs(Stack, { sx: __assign({ pb: 2, height: 1, position: 'fixed', width: NAV.W_DASHBOARD_MINI, borderRight: function (theme) { return "dashed 1px ".concat(theme.palette.divider); } }, hideScrollbarX), children: [_jsx(Logo, { sx: { mx: 'auto', my: 2 } }), _jsx(NavSectionMini, { data: getNavConfig(!!legalProvisionContext) })] })] }));
}
