var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// routes
import { PATH_DASHBOARD } from '@raas/shared/src/routes/paths';
import Iconify from '@raas/shared/src/components/minimals/iconify';
import { ICON_NAME } from '@raas/shared/src/utils/icons';
var icon = function (name) { return _jsx(Iconify, { icon: name }); };
var ICONS = {
    downloads: icon(ICON_NAME.downloads),
    gear: icon(ICON_NAME.gear),
    eye: icon(ICON_NAME.eye),
    tags: icon(ICON_NAME.tags),
    alerts: icon(ICON_NAME.alerts),
    reports: icon(ICON_NAME.reports),
    import: icon(ICON_NAME.import),
    tag: icon(ICON_NAME.tag),
    export: icon(ICON_NAME.export),
    requirement: icon(ICON_NAME.requirement),
    input: icon(ICON_NAME.input),
    legal: icon(ICON_NAME.legal),
    legalRequirement: icon(ICON_NAME.legalRequirement)
};
var navConfig = [
    {
        subheader: 'MENU',
        // the 'title' is actually a key that is then looked up in translation file
        items: [
            {
                title: 'legalProvisions.header',
                icon: ICONS.legal,
                path: PATH_DASHBOARD.legalProvision.root,
                versionDependent: false,
            },
            {
                title: 'legalRequirements.header',
                icon: ICONS.legalRequirement,
                path: PATH_DASHBOARD.legalRequirement.root,
                versionDependent: true,
                permissionRequired: 'LEGAL_REQUIREMENTS',
            },
            {
                title: 'technicalRequirements.header',
                icon: ICONS.requirement,
                path: PATH_DASHBOARD.technicalRequirement.root,
                versionDependent: true,
            },
            {
                title: 'properties.header',
                icon: ICONS.gear,
                path: PATH_DASHBOARD.property.root,
                versionDependent: true,
            },
            {
                title: 'inputs.header',
                icon: ICONS.input,
                path: PATH_DASHBOARD.input.root,
                versionDependent: true,
            },
            {
                title: 'alerts.header',
                icon: ICONS.alerts,
                path: PATH_DASHBOARD.alerts.list,
                versionDependent: false,
            },
        ],
    },
    {
        subheader: 'DATA MANAGEMENT',
        items: [
            {
                title: 'tagGroups.header',
                icon: ICONS.tags,
                path: PATH_DASHBOARD.tagGroup.root,
                versionDependent: true,
            },
            {
                title: 'tags.header',
                icon: ICONS.tag,
                path: PATH_DASHBOARD.tag.root,
                versionDependent: true,
            },
        ],
    },
    {
        subheader: 'reports.subHeader',
        items: [
            {
                title: 'import.header',
                path: PATH_DASHBOARD.import,
                icon: ICONS.import,
                versionDependent: false,
            },
            {
                title: 'reports.header',
                path: PATH_DASHBOARD.reports.list,
                icon: ICONS.reports,
                versionDependent: false,
            },
        ],
    },
    {
        subheader: 'EXPORT',
        items: [
            {
                title: 'exports.header',
                path: PATH_DASHBOARD.exports.list,
                icon: ICONS.export,
                versionDependent: false,
            },
        ],
    },
];
function getNavConfig(hasVersion) {
    if (hasVersion) {
        return navConfig;
    }
    return navConfig.map(function (group) {
        var items = group.items.filter(function (i) { return !i.versionDependent; });
        return __assign(__assign({}, group), { items: items });
    });
}
export default getNavConfig;
