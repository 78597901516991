import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { defaultLang } from './config-lang';
//
import enLocales from './langs/en';
// ----------------------------------------------------------------------
var lng = defaultLang.value;
var storageAvailable = localStorageAvailable();
if (storageAvailable) {
    lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources: {
        en: { translations: enLocales },
    },
    lng: lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;
