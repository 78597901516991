import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box, useTheme } from '@mui/material';
// components
import { useSettingsContext } from '@raas/shared/src/components/minimals/settings';
// hooks
import useResponsive from '@raas/shared/src/hooks/useResponsive';
//
import Header from '@raas/shared/src/components/header';
import Main from './Main';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
// ----------------------------------------------------------------------
export default function DashboardLayout() {
    var themeLayout = useSettingsContext().themeLayout;
    var theme = useTheme();
    var isDesktop = useResponsive('up', 'lg');
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var isNavHorizontal = themeLayout === 'horizontal';
    var isNavMini = themeLayout === 'mini';
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var renderNavVertical = _jsx(NavVertical, { openNav: open, onCloseNav: handleClose });
    if (isNavHorizontal) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), isDesktop ? _jsx(NavHorizontal, {}) : renderNavVertical, _jsx(Main, { sx: { backgroundColor: theme.palette.background.neutral }, children: _jsx(Outlet, {}) })] }));
    }
    if (isNavMini) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), _jsxs(Box, { sx: {
                        display: { lg: 'flex' },
                        minHeight: { lg: 1 },
                    }, children: [isDesktop ? _jsx(NavMini, {}) : renderNavVertical, _jsx(Main, { sx: {
                                backgroundColor: theme.palette.background.neutral,
                                minHeight: {
                                    lg: "calc(100vh)",
                                    marginTop: '12px',
                                },
                            }, children: _jsx(Outlet, {}) })] })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), _jsxs(Box, { sx: {
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 },
                }, children: [renderNavVertical, _jsx(Main, { sx: {
                            backgroundColor: theme.palette.background.neutral,
                            minHeight: {
                                lg: "calc(100vh)",
                                marginTop: '12px',
                            },
                        }, children: _jsx(Outlet, {}) })] })] }));
}
